@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "Poppins", sans-serif;
}
html,
body {
  overflow-x: hidden;
}

.swiper-button-prev,
.swiper-button-next {
  width: 40px !important;
  height: 40px !important;
  margin-top: calc(0px - (var(--swiper-navigation-size) / 1.5)) !important;
  color: #ffff !important;
  background-color: #ca8a04 !important;
  border-radius: 50%;
  -webkit-box-shadow: 0px 10px 39px -18px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 10px 39px -18px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 10px 39px -18px rgba(0, 0, 0, 0.75);
}

.swiper-button-prev:after,
.swiper-button-next:after {
  font-size: 20px !important;
  text-transform: none !important;
}
.swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled {
  opacity: 0 !important;
}

@media screen and (max-width: 640px) {
  .swiper-button-next,
  .swiper-rtl .swiper-button-prev {
    right: var(--swiper-navigation-sides-offset, 2px) !important;
    left: auto;
  }

  .swiper-button-prev,
  .swiper-rtl .swiper-button-next {
    left: var(--swiper-navigation-sides-offset, 2px) !important;
    right: auto;
  }
  .swiper-button-prev:after,
  .swiper-button-next:after {
    font-size: 12px !important;
    text-transform: none !important;
  }
  .swiper-button-prev,
  .swiper-button-next {
    width: 20px !important;
    height: 20px !important;
    margin-top: calc(0px - (var(--swiper-navigation-size) / 1.5)) !important;
    color: #ffff !important;
    background-color: #ca8a04 !important;
    border-radius: 50%;
    -webkit-box-shadow: 0px 10px 39px -18px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 10px 39px -18px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 10px 39px -18px rgba(0, 0, 0, 0.75);
  }
}

/* Hide the number input spinners for modern browsers */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}
.jodit-status-bar a.jodit-status-bar-link {
  background-color: transparent;
  color: rgba(0, 0, 0, 0.75);
  display: none !important;
}
.pdf-viewer-container {
  width: 100%;
  height: 100vh; /* Full viewport height */
  background-color: #f8f9fa; /* Optional background color */
  overflow: hidden; /* Prevent scrollbars */
}
.hide-scrollbar {
  overflow: auto;
  scrollbar-width: none; /* Firefox */
}

.hide-scrollbar::-webkit-scrollbar {
  display: none; /* Chrome, Safari, and Edge */
}
